<template>
  <div class="buyNotice">
    <div class="buyNotice__title">
      <img src="@/assets/運勢書共用/注意事項icon.svg" alt="" />
      注意事項
    </div>
    <!--  -->
    <div class="buyNotice__content" v-html="notice"></div>

    <!-- <div
      class="buyNotice__item"
      v-for="(item, index) in questList"
      :key="index"
      @click="$set(expands, index, !expands[index])"
    >
      <div class="buyNotice__item__question">
        <div>{{ item.Q }}</div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17 8.5C17 8.77614 16.7761 9 16.5 9H0.5C0.223858 9 0 8.77614 0 8.5C0 8.22386 0.223858 8 0.5 8H16.5C16.7761 8 17 8.22386 17 8.5Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 17C8.22386 17 8 16.7761 8 16.5L8 0.5C8 0.223858 8.22386 -3.39263e-08 8.5 -2.18557e-08C8.77614 -9.78513e-09 9 0.223858 9 0.5L9 16.5C9 16.7761 8.77614 17 8.5 17Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="buyNotice__item__answer" v-show="expands[index]">
        {{ item.A }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    notice: {
      type: String,
    },
  },
  data() {
    return {
      expands: [false, false, false],
      questList: [
        {
          Q: "目前有幾種付款方式？",
          A: "ATM轉帳、信用卡刷卡、Line Pay",
        },
        {
          Q: "解答之書付費後可再進行退款？",
          A: "不行喔。解答之書付費後代表功能售出，無法退費 (系統異常不在此限)",
        },
        {
          Q: "若遇功能當機、出現異常，該怎麼辦？",
          A:
            "請到選單中的『問題回報』填寫遇到問題，技術團隊會專門為你解決狀況。",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.buyNotice {
  background-color: #214c73;
  color: #fff;
  padding: 72px 0;
  min-height: 496px;
  margin-bottom: -70px;
  @include mobile {
    padding: 39px 52px 56px;
    min-height: unset;
  }
  &__title {
    max-width: 653px;
    font-weight: 400;
    line-height: 200%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 1.1px;
    @include mobile {
      font-size: 14px;
      letter-spacing: 0.7px;
      margin: unset;
      margin-bottom: 4px;
    }
    img {
      margin-right: 12px;
      height: 30px;
      width: 25px;
      @include mobile {
        margin-right: 8px;
        height: 29px;
        width: 25px;
      }
    }
  }
  &__content {
    max-width: 653px;
    margin: 0 auto;
    margin-top: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 1.8px;
    @include mobile {
      margin: 20px 0 0;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.7px;
    }
  }

  //暫時棄用
  &__item {
    max-width: 653px;
    margin: 0 auto;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 1.8px;
    padding-top: 24px;
    padding-bottom: 24px;
    @include mobile {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.7px;
    }
    &__question {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    &__answer {
      margin-top: 20px;
      @include mobile {
        margin-top: 10px;
      }
    }
  }
}
</style>
