export default {
  data() {
    return {};
  },
  computed: {
    _buyAllObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.Code === "All"
      );
    },
  },
  methods: {
    mixin_buySingle(nowProductObj, userType, receiverMemberId) {
      if (!this.$store.state.afterLogin) {
        window.localStorage.setItem("directionPath", this.$route.fullPath);
        return this.$store.commit("setLoginDialogStatus", true);
      }
      let orderObj = this.$store.state.orderCreater;
      orderObj.Quantity = 1;
      orderObj.ProductID = nowProductObj.ProductID;
      //Astrolabe為自己
      orderObj.Astrolabe = this.$store.state.userData.AstrolabeFriendLists.find(
        (item) => item.Relationship == "R0" && item.IsBelongToMember
      );
      orderObj.ReceiverMemberId = receiverMemberId
        ? receiverMemberId * 1 // 轉Int
        : null;

      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/order/checkInfo");
    },
    mixin_buyAll() {
      if (!this.$store.state.afterLogin) {
        window.localStorage.setItem("directionPath", this.$route.fullPath);
        return this.$store.commit("setLoginDialogStatus", true);
      }
      if (this._buyAllObj) {
        if (this._buyAllObj.isUnlock) {
          return this.$router.push({ name: "Book2024My" });
          // return this.$messagePopup(
          //   "已經解鎖過全部星座囉!",
          //   "您的2024解答之書內所有星座內容都已是解鎖狀態，不用重複進行購買～",
          //   "完成"
          // );
        }
        let orderObj = this.$store.state.orderCreater;
        orderObj.Quantity = 1;
        orderObj.ProductID = this._buyAllObj.ProductID;
        //Astrolabe為自己
        orderObj.Astrolabe = this.$store.state.userData.AstrolabeFriendLists.find(
          (item) => item.Relationship == "R0" && item.IsBelongToMember
        );

        this.$store.commit("updateOrderCreater", orderObj);
        this.$router.push("/order/checkInfo");
      }
    },
  },
};
