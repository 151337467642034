var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap", attrs: { id: "selectTarget" } }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _c("div", { staticClass: "filterBar" }, [
        _c("label", [
          _c("img", {
            staticClass: "serachIcon",
            attrs: { src: require("../../assets/占星小鋪/search.svg"), alt: "" }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filterKey,
                expression: "filterKey"
              }
            ],
            attrs: { type: "text", placeholder: "搜尋星盤名稱" },
            domProps: { value: _vm.filterKey },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.filterHandler.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filterKey = $event.target.value
              }
            }
          })
        ]),
        _c(
          "button",
          { staticClass: "filter_btn", on: { click: _vm.filterHandler } },
          [_vm._v("搜尋")]
        ),
        _c(
          "div",
          {
            staticClass: "addFriends_btn",
            on: {
              click: function($event) {
                return _vm.toAddFriend()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/占星小鋪/add friend.svg"),
                alt: ""
              }
            })
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "st_list_group unlock_area" },
      [
        _c(
          "v-row",
          _vm._l(_vm.List_filter, function(item) {
            return _c(
              "v-col",
              { key: item.AstrolabeFileId, attrs: { cols: "12" } },
              [
                _c("TargetCard", {
                  staticClass: "TargetCard-space mx-auto",
                  attrs: {
                    selected:
                      item.AstrolabeFileId == _vm.selectObj.AstrolabeFileId,
                    data: item
                  },
                  on: {
                    clicked: function($event) {
                      return _vm.selectHandler(item)
                    },
                    next: function($event) {
                      return _vm.next()
                    },
                    edit: function($event) {
                      return _vm.editHandler(item)
                    }
                  }
                })
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "st_title mr-auto" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/friend.svg"), alt: "" }
      }),
      _vm._v("\n      選擇星盤好友\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }