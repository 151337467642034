<template>
  <div class="unlockSingle" v-if="nowProductObj">
    <div class="unlockSingle__bg"></div>
    <div class="unlockSingle__title">
      <div class="unlockSingle__title__inside">
        <h1>
          {{
            nowProductObj.Code == "All" ? "全部購買" : nowProductObj.cn + "座"
          }}
        </h1>
        <h2>
          {{
            nowProductObj.Code == "All"
              ? nowProductObj.Name.split("-")[0]
              : nowProductObj.en
          }}
        </h2>
      </div>
    </div>
    <div class="unlockSingle__quote" v-html="nowProductObj.Description"></div>
    <div class="unlockSingle__intro">
      <div class="unlockSingle__intro__title">
        <img src="@/assets/運勢書共用/解答之書介紹icon.svg" alt="" />
        解答之書介紹
      </div>
      <div class="unlockSingle__intro__content">
        <div
          class="unlockSingle__intro__content__word"
          v-html="nowProductObj.Introduction"
        ></div>
      </div>
    </div>
    <div class="unlockSingle__toBuy">
      <div class="unlockSingle__toBuy__useType">
        <div class="unlockSingle__toBuy__useType__radio">
          <label for="personal">
            <input
              type="radio"
              name="useType"
              id="personal"
              value="personal"
              v-model="useType"
              @change="receiverMemberId = ''"
            />
            <span>自用</span>
          </label>
          <label for="gift">
            <input
              type="radio"
              name="useType"
              id="gift"
              value="gift"
              v-model="useType"
            />
            <span>送禮</span>
          </label>
        </div>

        <div
          class="unlockSingle__toBuy__useType__receiverMember"
          v-if="useType == 'gift'"
        >
          <div class="unlockSingle__toBuy__useType__receiverMember__id">
            <label for="receiverMemberId">送禮對象 ID</label>
            <input
              type="text"
              id="receiverMemberId"
              v-model.trim="receiverMemberId"
              placeholder="請輸入對方帳號 ID"
            />
          </div>
        </div>
      </div>
      <div
        class="unlockSingle__toBuy__buyArea"
        :class="{ isAll: nowProductObj.Code == 'All' }"
      >
        <div class="unlockSingle__toBuy__buyArea__price">
          NT$ {{ nowProductObj.Amount }}
        </div>
        <div
          class="unlockSingle__toBuy__buyArea__btn"
          :class="{ disabled: alreadyBuyPersonal }"
          @click="toBuy(nowProductObj)"
        >
          {{ alreadyBuyPersonal ? "已購買" : "立即購買" }}
          <img src="@/assets/運勢書共用/立即購買.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="unlockSingle__toBuyAll" v-if="nowProductObj.Code != 'All'">
      <div class="unlockSingle__toBuyAll__text">
        購買全部星座只需 400 元喔～
      </div>
      <div class="unlockSingle__toBuyAll__btn" @click="toBuyAll()">
        全部購買
        <img src="@/assets/運勢書共用/立即購買.svg" alt="" />
      </div>
    </div>
    <BuyNotice :notice="nowProductObj.Notice"></BuyNotice>

    <div class="alreadyBuyToast" v-show="showAlreadyBuyToast">
      本篇內容已解鎖
      <span class="hidden-mobile-down">，</span>
      <br class="hidden-mobile-up" />
      不需要再進行購買囉~
    </div>
  </div>
</template>

<script>
import BuyNotice from "@/components/運勢書2024/BuyNotice.vue";
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";
export default {
  mixins: [BuyBook2024Mixin],
  components: {
    BuyNotice,
  },
  data() {
    return {
      showAlreadyBuyToast: false,
      useType: "personal",
      receiverMemberId: "",
    };
  },
  computed: {
    _buyAllObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.Code === "All"
      );
    },
    astroId() {
      return this.$route.params.id;
    },
    nowProductObj() {
      if (this.astroId == this._buyAllObj.ProductID) return this._buyAllObj;
      return this.$store.getters.get_book2024_list.find(
        (item) => item.en === this.astroId
      );
    },
    alreadyBuyPersonal() {
      return this.$route.query.disabled && this.useType == "personal";
    },
  },
  created() {
    // if (this.nowProductObj.isUnlock) this.$router.push({ name: "Book2024My" });
    this.useType = this.$route.query.useType;
  },
  methods: {
    async toBuy(nowProductObj) {
      if (this.useType === "gift") {
        if (!this.receiverMemberId) {
          this.$noticeDiag("請輸入對方帳號 ID", "", "我知道了", () => {});
          return;
        } else {
          // 檢核能否送禮
          let vaildSendGift = false;
          try {
            let res = await this.$API.GET_CheckBuyGift(
              this.receiverMemberId,
              nowProductObj.ProductID
            );
            vaildSendGift = res.data.Data;
          } catch (e) {
            console.error(e);
          }
          if (!vaildSendGift) {
            this.$imageNotice({
              imageIdx: 1,
              title: "朋友已經買過囉",
              desc: `您想贈送的對象：(ID:${this.receiverMemberId}) 已經購買過，可以再從星星雜貨店另外挑選禮物喔！`,
              okText: "前往星星雜貨店",
              okEvent: () => {
                this.$router.push("/store");
              },
              cancelText: "返回當前頁面",
            });
            return;
          }
        }
      } else {
        if (nowProductObj.isUnlock) {
          this.showAlreadyBuyToast = true;
          setTimeout(() => {
            this.showAlreadyBuyToast = false;
          }, 2000);
          return;
        }
      }

      this.mixin_buySingle(nowProductObj, this.userType, this.receiverMemberId);
    },
    toBuyAll() {
      this.mixin_buyAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.unlockSingle {
  margin: -98px auto -80px;
  padding: 260px 0 80px;
  background: url("../../assets/運勢書共用/商品介紹_Web.png");
  min-height: 70vw;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  @include mobile {
    padding: 144px 0 55px;
    margin: -60px auto -55px;
    min-height: 193.3vw;
    background: url("../../assets/運勢書共用/商品介紹_Mobile.png");
  }
  &__bg {
    width: 390px;
    height: 300px;
    background: url("../../assets/運勢書共用/單一商品介紹背景.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 53px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 45;
    @include mobile {
      width: 279.956px;
      height: 200px;
      top: 0;
      background: none;
    }
  }
  &__title {
    width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &__inside {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h1 {
        color: #fff;
        font-size: 44px;
        font-weight: 700;
        line-height: 132%;
        letter-spacing: 3.52px;
        @include mobile {
          font-size: 32px;
          font-weight: 700;
          line-height: 132%;
          letter-spacing: 2.56px;
        }
      }
      h2 {
        color: #fff;
        font-family: El Messiri;
        font-size: 16.651px;
        font-weight: 400;
        line-height: 132%;
        letter-spacing: 0.833px;
        @include mobile {
          font-size: 12px;
          font-weight: 400;
          line-height: 132%;
          letter-spacing: 0.6px;
        }
      }
    }
  }
  &__quote {
    font-weight: 400;
    max-width: 457px;
    margin: 40px auto 68px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 2px;
    text-align: center;
    @include mobile {
      margin: 40px 52px 0;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.8px;
      text-align: left;
    }
  }
  &__intro {
    max-width: 672px;
    margin: 0 auto;
    margin-top: 40px;
    @include mobile {
      margin: 69px 52px 0;
    }
    &__title {
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      line-height: 160%;
      letter-spacing: 1.2px;
      display: flex;
      align-items: center;
      @include mobile {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.8px;
      }
      img {
        width: 25px;
        height: 30px;
        margin-right: 12px;
        @include mobile {
          width: 25px;
          height: 30px;
          margin-right: 8px;
        }
      }
    }
    &__content {
      margin-top: 24px;
      @include mobile {
        margin: 20px 0 0;
      }
      &__word {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: 1.8px;
        text-align: justify;
        @include mobile {
          font-size: 14px;
          font-weight: 400;
          line-height: 200%; /* 28px */
          letter-spacing: 0.7px;
        }
      }
    }
  }
  &__toBuy {
    max-width: 672px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 64px auto 32px;
    @include mobile {
      margin: 48px 52px;
      flex-direction: column;
      align-items: flex-start;
    }
    //選擇自用會送禮
    &__useType {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      margin-top: 0.5em;
      @include mobile {
        margin-right: 0;
      }
      &__radio {
        display: flex;
        label {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 28px;
          color: #ffffff;
          cursor: pointer;
          margin-right: 24px;
          input {
            display: none;
          }
          span {
            position: relative;
            padding-left: 30px;
            &:before {
              content: "";
              width: 22px;
              height: 22px;
              border-radius: 50%;
              background: #f0f2f4;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            &:after {
              content: "";
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #214c73;
              position: absolute;
              left: 6px;
              top: 50%;
              transform: translateY(-50%);
              display: none;
            }
          }
          input:checked + span {
            &:after {
              display: block;
            }
          }
        }
      }
      //一個僅有下劃線的輸入框
      &__receiverMember {
        max-width: 672px;
        display: flex;
        align-items: center;
        margin: 20px auto 0;
        &__id {
          max-width: 232px;
          label {
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            margin-top: 20px;
            margin-bottom: 8px;
          }
          input {
            border: none;
            border-bottom: 1px solid #fff;
            background: none;
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            font-weight: 700;
            padding: 0;
            width: 100%;
            &::placeholder {
              color: #d9d9d965;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    &__buyArea {
      display: flex;
      align-items: center;
      @include mobile {
        margin-top: 36px;
        width: 100%;
        justify-content: space-between;
      }
      &.isAll {
        margin-bottom: 32px;
        @include mobile {
          margin-bottom: 24px;
        }
      }
      &__price {
        color: #fff;
        font-family: Abhaya Libre;
        font-size: 32px;
        font-weight: 400;
        margin-right: 44px;
        @include mobile {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 17.813px;
          margin-right: 0;
        }
      }
      &__btn {
        display: flex;
        align-items: center;
        color: #fff;
        border-radius: 50px;
        border: 1px solid #fff;
        background: #214c73;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.32);
        padding: 8px 24px 10px 24px;

        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1px;
        cursor: pointer;
        @include mobile {
          padding: 6px 16px 4px 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.8px;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
          @include mobile {
            width: 23px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  &__toBuyAll {
    max-width: 672px;
    margin: 0 auto 48px;
    padding: 20px 28px;
    border-radius: 15px;
    background: rgba(#fff, 0.1);
    display: flex;
    justify-content: space-between;
    @include mobile {
      margin: 0 52px 48px;
      padding: 16px 20px;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
    }
    &__text {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 200%;
      letter-spacing: 1.8px;
      @include mobile {
        font-size: 16px;
        letter-spacing: 0.8px;
        margin-right: auto;
      }
    }
    &__btn {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      line-height: 150%; /* 30px */
      letter-spacing: 1px;
      align-items: center;
      position: relative;
      z-index: 0;
      display: inline-flex;
      cursor: pointer;
      @include mobile {
        margin-top: 12px;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        @include mobile {
          width: 29px;
          margin-left: 8px;
        }
      }
      &:before {
        content: "";
        width: 100%;
        height: 16px;
        border-radius: 4.765px;
        background-color: #d3409d;
        position: absolute;
        left: -0.5em;
        bottom: 0;
        z-index: -1;
        @include mobile {
          height: 16px;
          bottom: -0.1em;
        }
      }
    }
  }
}

//已購買提醒
.alreadyBuyToast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 390px;
  height: 112px;
  border-radius: 20px;
  border: 0.8px solid #d9d9d9;
  background: rgba(33, 76, 115, 0.6);
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1.08px;

  @include mobile {
    width: 256px;
    height: 96px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1.28px;
  }
}
</style>
