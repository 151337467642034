<template>
  <div class="targetCard" @click="clicked()" :class="{ selected: selected }">
    <div class="pic_box">
      <img :src="data.ProfilePhotoSrc" alt="" />
    </div>
    <div class="info_area">
      <div class="name hidden-mobile-down">
        {{ $NameFormat(data.Name, 24) }}
      </div>
      <div class="name hidden-mobile-up">{{ $NameFormat(data.Name) }}</div>
      <div class="birthPlace hidden-mobile-down mb-2">
        {{
          data.BirthDate
            ? data.BirthPlace
            : `${data.BirthCountry},${data.BirthCity}`
        }}
      </div>
      <div class="birthDay mb-2">
        <span v-if="data.BirthDate">
          <span class="mr-2">{{ data.BirthDate.split(" ")[0] }}</span>
          <span :class="{ 'hidden-mobile-down': !unlock }"
            >{{ data.BirthDate.split(" ")[1] }}
          </span>
          <img
            v-if="data.IsDaylight"
            class="ml-1"
            src="@/assets/會員相關/sun.svg"
          />
        </span>
        <span v-else>
          <span class="mr-2">{{ data.BirthDay }}</span>
          <span class="{'hidden-mobile-down': !unlock}">{{
            data.BirthTime
          }}</span>
          <img
            v-if="data.IsDaylight"
            class="ml-3"
            src="@/assets/會員相關/sun.svg"
          />
        </span>
      </div>
      <div class="platform" v-show="!data.IsBelongToMember">
        {{ data.Platform }}
      </div>
      <div class="unlock_group">
        <div class="unlock_item" v-if="data.IsUnlockBC">
          <div class="icon_box">
            <img src="../../assets/占星小鋪/unlock.svg" alt="" />
          </div>
          <span>宮位解讀</span>
        </div>
        <div class="unlock_item" v-if="data.IsUnlockLC">
          <div class="icon_box">
            <img src="../../assets/占星小鋪/unlock.svg" alt="" />
          </div>
          <span>推運功能</span>
        </div>
      </div>
    </div>
    <div class="control">
      <div class="btn_group">
        <div class="select_btn" :class="{ hidden: selected }">
          <img
            src="@/assets/icon-edit.svg"
            title="編輯"
            @click="eventHandler('edit')"
          />
        </div>
        <div class="select_btn" :class="{ active: selected }">
          <img src="../../assets/占星小鋪/select.svg" alt="" />
        </div>
      </div>
      <div class="next" v-show="selected" @click="eventHandler('next')">
        <span>下一步</span>
        <img src="@/assets/占星小鋪/下一步.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "selected", "unlock"],
  methods: {
    clicked() {
      this.$emit("clicked");
    },
    eventHandler(doWhat) {
      switch (doWhat) {
        case "delete":
          this.$emit("delete");
          break;
        case "edit":
          this.$emit("edit");
          break;
        default:
          this.$emit("next");
          break;
      }
    },
  },
  computed: {
    isSelf() {
      return (
        this.data.AstrolabeFileId ===
        this.$store.state.userData.AstrolabeFriendLists[0].AstrolabeFileId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.targetCard {
  width: 552px;
  height: auto;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 25px 50px;
  padding: 25px;
  display: flex;
  cursor: pointer;
  position: relative;
  @include mobile {
    width: auto;
    padding: 14px 16px;
    border-radius: 25px 35px;
    box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
      4px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .platform {
    display: inline-block;
    padding: 2px 12px;
    text-align: center;
    background: #f0f2f4;
    font-size: 12px;
    line-height: 17px;
    box-shadow: 2px 2px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    color: rgba(#17445c, 0.6);
  }
  .control {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    .btn_group {
      display: flex;
      justify-content: flex-end;
    }
    .next {
      width: 136px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 2px solid #9b9b9b;
      color: #9b9b9b;
      font-size: 16px;
      line-height: 26px;
      position: relative;
      padding-right: 6px;
      img {
        margin-left: 11px;
      }
      @include mobile {
        width: 70px;
        height: 36px;
        margin-top: 18px;
        font-size: 14px;
        line-height: 26px;
        img {
          width: 13px;
          margin-left: 4px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        background: #d9d9d9;
        opacity: 0.6;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        @include mobile {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  .select_btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    margin-left: 20px;
    &.hidden {
      visibility: hidden;
    }
    &.active {
      box-shadow: inset -2px -4px 10px #ffffff, inset -2px -4px 10px #ffffff,
        inset 0px 0px 10px rgba(0, 0, 0, 0.45), -1px -1px 4px #ffffff,
        -1px -1px 4px #ffffff, 2px 2px 6px rgba(0, 0, 0, 0.25);
    }
    @include mobile {
      width: 28px;
      height: 28px;
      top: 18px;
      right: 20px;
      margin-left: 8px;
    }
    img {
      margin: auto;
      width: 20px;
    }
  }
  &.selected {
    box-shadow: inset -2px -4px 10px #ffffff, inset -2px -4px 10px #ffffff,
      inset 0px 0px 10px rgba(0, 0, 0, 0.45);
  }
  .pic_box {
    width: 80px;
    height: 80px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    margin-right: 40px;
    @include mobile {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 16px;
      top: -8px;
    }
    img {
      width: 64px;
      margin: auto;
      @include mobile {
        width: 48px;
      }
    }
  }
  .info_area {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #9b9b9b;
    @include mobile {
      margin-left: 75px;
    }
    .name {
      color: #767676;
      font-size: 20px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 8px;
      @include mobile {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 1px;
      }
    }
    .birthDay {
      @include mobile {
        font-size: 12px;
      }
    }
    .unlock_group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .unlock_item {
        display: flex;
        align-items: center;
        height: 24px;
        background: $secondary-color-2;
        border-radius: 100px;
        font-size: 14px;
        color: #767676;
        padding: 4px 8px;
        margin-bottom: 4px;
        &:not(:last-of-type) {
          margin-right: 4px;
        }
        .icon_box {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          margin-right: 5px;
          img {
            width: 8px;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
