import { render, staticRenderFns } from "./SelectTarget.vue?vue&type=template&id=ab5586a8&scoped=true&"
import script from "./SelectTarget.vue?vue&type=script&lang=js&"
export * from "./SelectTarget.vue?vue&type=script&lang=js&"
import style0 from "./SelectTarget.vue?vue&type=style&index=0&id=ab5586a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab5586a8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/joe/Desktop/DaMou-Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab5586a8')) {
      api.createRecord('ab5586a8', component.options)
    } else {
      api.reload('ab5586a8', component.options)
    }
    module.hot.accept("./SelectTarget.vue?vue&type=template&id=ab5586a8&scoped=true&", function () {
      api.rerender('ab5586a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/占星小舖/SelectTarget.vue"
export default component.exports